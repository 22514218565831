<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      class="search-form"
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      :labelWidth="80"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="渠道选择" slot="operationSelect1">
        <el-select
          v-model="matchChannelId"
          placeholder="请选择撮合渠道"
          filterable
          clearable
        >
          <el-option
            v-for="item in channelSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="回收商家" slot="operationSelect2">
        <el-select
          v-model="merchantId"
          placeholder="请选择商家名称"
          filterable
          clearable
          clear="clearMerchant"
        >
          <el-option
            v-for="item in merchantSelectList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="门店商家" slot="operationSelect3">
        <el-select
          v-model="companyId"
          placeholder="请选择商家名称"
          filterable
          clearable
        >
          <el-option
            v-for="item in companySelectList"
            :key="item.companyId"
            :label="item.companyName"
            :value="item.companyId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单状态" slot="state">
          <el-select v-model="orderState" placeholder="请选择订单状态" clearable>
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="选择时间" slot="timeSelect">
        <el-date-picker
          v-model='entryTime'
          type="daterange"
          :picker-options="pickerOptions"
          start-placeholder="开始日期"
					range-separator="~"
          end-placeholder="结束日期"
					clearable
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="订单成交时间"
        slot="ackTime"
        style="margin: 0"
        label-width="100px"
      >
        <el-date-picker
          v-model="ackEntryTime"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from 'moment'
let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/
  if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号码'))
  } else {
    callback()
  }
}
export default {
  name: 'Form',
  data() {
    return {
      day60: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day60 ||
              time.getTime() < this.pickerMinDate - this.day60
            );
          }
          return false;
        },
      },
      entryTime: [
          moment()
            .day(moment().day())
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      initData: {
        opt: '00'
      },
      matchChannelId:"",
      merchantId: "",
      companyId: "",
      ackEntryTime: null,
      channelSelectList: [],
      merchantSelectList: [],
      companySelectList: [],
      orderStatusList: [],
      orderState: "",
      data1Map: new Map(),
      data2Map: new Map(),
      pickerMinDate: null,
      pickerMaxDate: null,
      formItemList: [
        {
          key: 'orderNo',
          type: 'input',
          lableWidth: '70px',
          labelName: '订单号',
          placeholder: '请输入订单号',
        },
        { slotName: 'operationSelect1' },
        { slotName: 'operationSelect2' },
        { slotName: 'operationSelect3' },
        {
          key: 'opt',
          type: 'select',
          labelName: '账单状态',
          lableWidth:'80',
          option: [
            {
              value: '00',
              label: '全部',
            },
            {
              value: '01',
              label: '撮合入账',
            },
            {
              value: '02',
              label: '撮合出账',
            },
         
          ],
        },
        { slotName: 'state' },
        { slotName: 'timeSelect' },
        { slotName: 'ackTime' },
      ],
      formRules: {
      },
    }
  },
  created(){
    // let startTime = moment().day(moment().day() - 6).format('YYYY-MM-DD 00:00:00') // 当前时间往前推15天的时间
    // let endTime = moment().format('YYYY-MM-DD 23:59:59')
    // this.entryTime = [startTime,endTime]
    this.getStatusList()
    this.handleConfirm()
    this.getMatchChannels()
    this.getMatchMerchantList()
    this.getMatchCompanyList()
  },
  watch:{
  },
  methods: {
    handleConfirm(data,cd) {
      if (this.entryTime === null) {
        (this.startTime = ''), (this.endTime = '')
      } else {
        (this.startTime = moment(this.entryTime[0]).format("x")),
          (this.endTime = moment(this.entryTime[1]).format("x"))
      }
      if (this.ackEntryTime === null) {
        (this.ackStartTime = ""), (this.ackEndTime = "");
      } else {
        (this.ackStartTime = moment(this.ackEntryTime[0]).format("x")),
          (this.ackEndTime = moment(this.ackEntryTime[1]).format("x"));
      }
      console.log(data);
      const SeachParams = {
        merchantId:this.merchantId,
        companyId:this.companyId,
        endTime:this.endTime,
        matchChannelId:this.matchChannelId,
        startTime:this.startTime,
        ackStartTime: this.ackStartTime,
        ackEndTime: this.ackEndTime,
        opt: data ? data.opt : '',
        orderNo: data ? data.orderNo : '',
        orderState: this.orderState,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      }
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    },
    // 获取状态下拉列表
    getStatusList() {
      _api.orderStatusList().then(res => {
        let arr = []
        for (const item of res.data) {
          arr.push({
            value: item.key,
            label: item.value
          })
        }
        this.orderStatusList = arr
      })
    },
    // 获取渠道
    getMatchChannels() {
      _api.getMatchChannelSelect().then(res => {
        if (res.code === 1) {
          this.channelSelectList = res.data;
        }
      });
    },
    // 获取回收商家
    getMatchMerchantList() {
      _api.matchMerchantList({pageNum: 1,pageSize:99999}).then(res => {
        if (res.code === 1) {
          this.merchantSelectList = res.data;
        }
      });
    },
    // 获取门店商家
    getMatchCompanyList(id) {
      _api.matchCompanyList({pageNum: 1,pageSize:99999}).then(res => {
        if (res.code === 1) {
          this.companySelectList = res.data;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{
  /deep/ .el-form--inline .el-form-item{
    // margin-right: 20px!important;
  }
  .search-form{
    /deep/.el-select > .el-input {
      width: 170px !important;
    }
  }
  .add-form{
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
    /deep/.el-input-group__prepend{
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
    /deep/.el-select{
      width: 100%;
    }
  }
}
</style>
